import React from "react";
import { Helmet } from "react-helmet";

function SeoManager({ title, metaDetails, canonicalUrl, logoImage, ogTitle }) {
  const ogTagsList = ["description"];
  const currentPageUrl = window.location.href;

  return (
    <Helmet defaultTitle="Top Coaching Academies for Sports & Fitness">
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={window.location.href} />

      {metaDetails?.map((metaDetail) => (
        <meta
          key={metaDetail.key}
          name={metaDetail.key}
          content={metaDetail.value}
        />
      ))}
      <meta
        name="description"
        content="Discover the best societies and academies offering top-notch sports and fitness facilities, including badminton, cricket, swimming, tennis, and more."
      />
      <meta
        property="og:title"
        content="Top Coaching Academies for Sports & Fitness"
      />

      <meta
        property="og:description"
        content="Discover the best societies and academies offering top-notch sports and fitness facilities, including badminton, cricket, swimming, tennis, and more."
      />

      {logoImage ? (
        <meta property="og:image" content={logoImage} />
      ) : (
        <meta
          property="og:image"
          content="https://pbs.twimg.com/profile_images/1848302627966390272/iVzRCqty.jpg"
        />
      )}
      <meta property="og:url" content={currentPageUrl} />
      <meta
        name="keywords"
        content="Book Sports Coaching, Book Sports Academies, Sports & Fitness in India"
      />
      <meta name="robots" content="index, follow" />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="twitter:title" content="Largest sports ecosystem in India" />
      <meta
        name="twitter:description"
        content="Largest sports ecosystem in India. Book Sports Coaching, Book Sports Academies, Sports & Fitness in India."
      />
      <meta
        name="twitter:image"
        content="https://pbs.twimg.com/profile_images/1848302627966390272/iVzRCqty.jpg"
      />
    </Helmet>
  );
}

export default SeoManager;
